import { makeStyles } from '@material-ui/styles';
import { ORANGE } from '../../constants/colors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflow: 'hidden',
    margin: '20px auto 0',
    borderRadius: 8,
    boxShadow: '0 -10px 30px rgba(0,0,0,0.3)',
  },
  legend: {
    background: 'white',
    opacity: '0.9',
    color: ORANGE,
    position: 'absolute',
    bottom: 40,
    left: '50%',
    marginLeft: '-45%',
    width: '90%',
    padding: 10,
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
    borderRadius: 8,
    boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
  },
});

export default useStyles;
