import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import useStyles from './carousel.style';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "carousel" }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          id
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return data.allFile.nodes.map((node) => ({
    ...node.childImageSharp,
    id: node.id,
    name: node.name,
    publicURL: node.publicURL,
  }));
};

const CarouselComponent = () => {
  const classes = useStyles();
  const images = useGallery();

  return (
    <Carousel
      width="100%"
      dynamicHeight
      infiniteLoop
      autoPlay
      interval={4000}
      className={classes.root}
      showStatus={false}
      showThumbs={false}
      swipeable
    >
      {images.map((image, index) => (
        <div key={index}>
          <Img fluid={image.fluid} />
          <p className={classes.legend}>{image.name}</p>
        </div>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
