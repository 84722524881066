import React, { useCallback } from 'react';
import { useNavigate } from '@reach/router';
import Layout from '../components/layout';

import SEO from '../components/seo';
import CarouselComponent from '../components/Carousel/carousel';
import useStyles from '../pages-styles/index.style';
import Section from '../components/Shared/Section/section';

const IndexPage = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const handleCTAClick = useCallback(() => {
    navigate('/oferta');
  }, [navigate]);

  return (
    <Layout rootClassName={classes.root}>
      <SEO title="Strona główna" />
      <section className={classes.section}>
        <Section
          headerText={'Firma Toms-Bud oferuje profesjonalny dobór i montaż:'}
          listItemTexts={[
            'Kotłów gazowych i olejowych',
            'Pomp ciepła',
            'Instalacji wodno-kanalizacyjnych',
            'Instalacji centralnego ogrzewania',
            'Instalacji gazowych (również próby szczelności)',
          ]}
          button={{ onClick: handleCTAClick, text: 'Zapoznaj się z naszą ofertą!' }}
        />
      </section>
      <section className={classes.section}>
        <CarouselComponent />
      </section>
    </Layout>
  );
};

export default IndexPage;
